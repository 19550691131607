import React, { useState, useRef } from "react"
import * as D3 from "d3"
import { graphql } from "gatsby"
import { Dropdown } from "semantic-ui-react"
import SEO from "../../../components/seo"
import useContainerDimensions from "../../../components/useContainerDimensions"
import barChart from "../../../components/barChart"
import styles from "./shelter-animal-counts.module.css"
import "../../general.css"
import {
  chartTitleTextStyle,
  vizBackgroundStyle
} from "../../../defines";

const Title = "Shelter animal counts and outcomes";
const MinYear = 2017;
const MaxYear = 2019;

export const query = graphql`
  query {
    allShelterAnimalCountsJson {
        nodes {
          year
          dogs {
            intakes {
              stray
              relinquished
              ownerIntendedEuthanasia
              transferred
              other
            }
            outcomes {
              adoption
              returnedToOwner
              transferred
              returnedToField
              other
              diedInCare
              lostInCare
              euthanized
              ownerIntendedEuthanasia
            }
          }
          cats {
            intakes {
              stray
              relinquished
              ownerIntendedEuthanasia
              transferred
              other
            }
            outcomes {
              adoption
              returnedToOwner
              transferred
              returnedToField
              other
              diedInCare
              lostInCare
              euthanized
              ownerIntendedEuthanasia
            }
          }
        }
      }
  }
`;

const getYearList = () => {
  return D3.range(MinYear, MaxYear + 1).map(n => ({
    key: n,
    value: n,
    text: n
  }));
}

const getAnimalList = () =>
  [
    {
      key: "Cats",
      value: "Cats",
      text: "Cats"
    },
    {
      key: "Dogs",
      value: "Dogs",
      text: "Dogs"
    }
  ];

const transformData = (data) => {
  let result = [];
  data.forEach(function (yearData) {
    let transformed = {
      year: yearData.year,
      cats: {
        intakes: {
          "Other": yearData.cats.intakes.other,
          "Euthanasia": yearData.cats.intakes.ownerIntendedEuthanasia,
          "Gave up": yearData.cats.intakes.relinquished,
          "Stray": yearData.cats.intakes.stray,
          "From agency": yearData.cats.intakes.transferred
        },
        outcomes: {
          "Adopted": yearData.cats.outcomes.adoption,
          "Died": yearData.cats.outcomes.diedInCare,
          "Euthanized": yearData.cats.outcomes.euthanized + yearData.cats.outcomes.ownerIntendedEuthanasia,
          "Lost": yearData.cats.outcomes.lostInCare,
          "Other": yearData.cats.outcomes.other,
          "To field": yearData.cats.outcomes.returnedToField,
          "To owner": yearData.cats.outcomes.returnedToOwner,
          "To agency": yearData.cats.outcomes.transferred
        }
      },
      dogs: {
        intakes: {
          "Other": yearData.dogs.intakes.other,
          "Euthanasia": yearData.dogs.intakes.ownerIntendedEuthanasia,
          "Gave up": yearData.dogs.intakes.relinquished,
          "Stray": yearData.dogs.intakes.stray,
          "From agency": yearData.dogs.intakes.transferred
        },
        outcomes: {
          "Adopted": yearData.dogs.outcomes.adoption,
          "Died": yearData.dogs.outcomes.diedInCare,
          "Euthanized": yearData.dogs.outcomes.euthanized + yearData.dogs.outcomes.ownerIntendedEuthanasia,
          "Lost": yearData.dogs.outcomes.lostInCare,
          "Other": yearData.dogs.outcomes.other,
          "To field": yearData.dogs.outcomes.returnedToField,
          "To owner": yearData.dogs.outcomes.returnedToOwner,
          "To agency": yearData.dogs.outcomes.transferred
        }
      }
    };
    result.push(transformed);
  });
  return result;
}

const ShelterAnimalCounts = ({ data, mobile }) => {
  let GraphAspectRatio = 4 / 3;
  if (mobile) {
    GraphAspectRatio = 5 / 6;
  }
  const transformedData = transformData(data.allShelterAnimalCountsJson.nodes);
  const [selectedYear, setSelectedYear] = useState(MaxYear);
  const [selectedAnimal, setSelectedAnimal] = useState("Dogs");
  const selectedData =
    transformedData
      .filter(d => d.year === selectedYear)[0][selectedAnimal.toLowerCase()];

  const onYearDropdownChanged = (event, result) => {
    let { value } = result || event.target;
    setSelectedYear(value);
  };

  const onAnimalChanged = (event, result) => {
    let { value } = result || event.target;
    setSelectedAnimal(value);
  }

  const graphDivRef = useRef();
  const { width } = useContainerDimensions(graphDivRef);
  const svgWidth = Math.max((width - 10), 0);
  const svgHeight = Math.max((width - 10) / GraphAspectRatio, 0);
  const intakesMargin = {
    left: mobile ? 30 : 50,
    top: 40,
    right: 25,
    bottom: 30
  };
  const outcomesMargin = {
    left: 0,
    top: 30,
    right: mobile ? 42 : 60,
    bottom: 30
  };
  const intakesColor = "#74c476";
  const outcomesColor = "#238b45";

  if (typeof document === "undefined") {
    return null;
  }

  const intakesSvg = D3.select(".intakes");
  const outcomesSvg = D3.select(".outcomes");

  const renderIntakes = () => {
    let barChartG = intakesSvg.select(".barChartG");
    if (barChartG.empty()) {
      barChartG = intakesSvg.append("g");
      barChartG.attr("class", "barChartG");
    }
    barChartG.call(barChart, {
      ascending: false,
      data: selectedData.intakes,
      width: svgWidth,
      height: svgHeight,
      margin: intakesMargin,
      fillColor: intakesColor,
      tooltipClass: styles.tooltip,
      valuesAxisFormat: ".2s",
      mobile
    });
  }

  const renderOutcomes = () => {
    let barChartG = outcomesSvg.select(".barChartG");
    if (barChartG.empty()) {
      barChartG = outcomesSvg.append("g");
      barChartG.attr("class", "barChartG");
    }
    barChartG.call(barChart, {
      ascending: true,
      data: selectedData.outcomes,
      width: svgWidth,
      height: svgHeight,
      margin: outcomesMargin,
      fillColor: outcomesColor,
      tooltipClass: styles.tooltip,
      valuesAxisFormat: ".2s",
      splitAxisOnSpace: mobile,
      mobile
    });
  }

  renderIntakes();
  renderOutcomes();
  return (
    <>
      <SEO title={Title} />
      <div className="contentDiv">
        <h2 className="chartTitle" style={chartTitleTextStyle(mobile)}>{Title}</h2>
        <div className={styles.tooltip} style={{ opacity: 0 }} />
        <div className={styles.dropDown}>
          <Dropdown
            placeholder="Animal"
            selection
            options={getAnimalList()}
            value={selectedAnimal}
            compact
            basic
            onChange={onAnimalChanged}
          />
          <Dropdown
            placeholder="Year"
            selection
            options={getYearList()}
            value={selectedYear}
            compact
            basic
            onChange={onYearDropdownChanged}
            style={{ marginBottom: 10 }}
          />
        </div>
        <div className={`${styles.wrapper} vizBackground`} ref={graphDivRef} style={vizBackgroundStyle(mobile)}>
          <svg className="intakes" width={`${svgWidth}px`} height={`${svgHeight}px`} style={{ marginBottom: 20 }}>
            <text x={`${svgWidth / 2}`} y="20" fill={`${intakesColor}`} style={{ fontSize: "1.2em" }} textAnchor="middle" alignmentBaseline="central">{`${selectedAnimal} coming into shelters`}</text>
          </svg>
          <svg className="outcomes" width={`${svgWidth}px`} height={`${svgHeight}px`} style={{ marginBottom: 20 }}>
            <text x={`${svgWidth / 2}`} y="10" fill={`${outcomesColor}`} style={{ fontSize: "1.2em" }} textAnchor="middle" alignmentBaseline="central">{`${selectedAnimal} leaving shelters`}</text>
          </svg>
          <div className="citation">
            Data source: <a href="https://shelteranimalscount.org/">Shelter Animal Counts</a><br />
            The information contained herein was derived from data supplied by Shelter Animals Count.
            Shelter Animals Count specifically disclaims all responsibility for any analysis, interpretations,
            conclusions and opinions contained in the information presented.
          </div>
        </div>
      </div>
    </>
  );
}

export default ShelterAnimalCounts;
